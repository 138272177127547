body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont,'Microsoft YaHei','Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: auto;
    background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre{
    white-space:pre-wrap;
    word-wrap:break-word;
}
